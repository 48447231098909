.react-select-pane__control {
    cursor: text;
    margin-bottom: 16px;
}

.react-select-pane__option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.react-select-pane__control--menu-is-open{
    .react-select-pane__single-value{
        color: #D3D3D3
    }
}
