.create-staffer-form {
  .staffer-image {
    .uploaded-image {
      max: {
        // These match the 'medium' size in course-discovery's model definition for the image field
        width: 110px;
        height: 110px;
      }
    }
  }

  // react-select specific styles
  .react-select__menu {
    z-index: 5;
  }

  .react-select__control {
    cursor: text;
  }

  .react-select__control.danger {
    border-color: red;
    box-shadow: none;
  }

  .react-select__control--menu-is-open{
    .react-select__single-value{
      color: #D3D3D3
    }
  }
}
