@import "~@edx/brand/paragon/fonts";
@import "~@edx/brand/paragon/variables";
@import "~@openedx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";
@import "~@edx/frontend-component-footer/dist/footer";
@import "./dark-mode";

// Components
@import '../components/EditCoursePage/EditCourseForm';
@import '../components/EditCoursePage/CollapsibleCourseRun';
@import '../components/Header/Header';
@import '../components/RichEditor/RichEditor';
@import '../components/StafferPage/StafferForm';
@import '../components/ListField/ListField';
@import '../components/SidePanes/CommentsPane';

::-webkit-search-cancel-button {
  display: none;
}
