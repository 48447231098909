.create-course-form {
    // react-select specific styles
    .react-select__control {
      cursor: text;
      border-color: #707070;
      // match the height of edx-paragon form fields
      height: calc(1.3333em + 1.125rem + 2px);
    }
    
    .react-select__dropdown-indicator{
        color: #707070;
    }
  
    .react-select__control--menu-is-open{
      .react-select__single-value{
        color: #D3D3D3
      }
    }
  }
