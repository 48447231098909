$comment-border-color: lightgray;

.card-body .scroll-comments {
  max-height: 250px;
}

.comment-info {
  font-size: 0.9rem;
}

.comment-body {
  font-size: 0.9rem;
}

.post-comment-btn {
  margin-top: 8px;
}
